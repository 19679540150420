@use 'breakpoints' as *;

/* Basic styles */

.nav-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.2s;
}

.nav--hidden {
  transform: translateY(calc(-1 * var(--nav-height)));
  box-shadow: none;
}

nav {
    background: #333;
    padding: 0 20px;
    z-index: 1040;
    
    ul {
        padding: 0;
        margin: 0 auto 1.2rem auto;
    }
    a {
        color: white;
        text-decoration: none;
    }
    .menu,
    .submenu {   
        list-style-type: none;
        margin-top: 0;
        justify-content: space-between;
    }
    .logo {
        height: 80px;
        margin-right: 2rem;

        img {
            height: 100px;
            width: 100px;

            @media (max-width: $mobile){
              height: 80px;
              width: 80px;
            }
        }
    }
    .item {
        padding: 10px;

        &:hover {
            cursor: pointer;
        }

        &.active {
            a:after {
                width: 0;
                height: 0;
                border-left: 10px solid rgba(230, 230, 230, 0.05);
                border-right: 10px solid transparent;
                border-bottom: 10px solid #37c0fb;
                display: block;
                position: absolute;
                bottom: -20px;
                content: "";
                left: 40%;
            }
        }
    }
    .item.button {
        padding: 9px 5px;
    }
    .item:not(.button) a:hover,
    .item a:hover::after {
        color: #ccc;
    }

    /* Mobile menu */
    .menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    
        li a {
            display: block;
            padding: 8px 5px;
        }
        li.subitem a {
        padding: 15px;
        }
    }
    .toggle {
        order: 1;
        font-size: 20px;
    }
    .item.button {
        order: 2;
    }
    .item {
        order: 3;
        width: 100%;
        text-align: center;
        display: none;
    }
    .active .item {
        display: block;
    }
    .button.secondary { /* divider between buttons and menu links */
        border-bottom: 1px #444 solid;
    }

    /* Submenu up from mobile screens */
    .submenu {
        display: none;
    }
    .submenu-active .submenu {
    display: block;
    }
    .has-submenu i {
        font-size: 12px;
    }
    .has-submenu > a::after {
        font-family: "Font Awesome 5 Free";
        font-size: 12px;
        line-height: 16px;
        font-weight: 900; 
        content: "\f078";
        color: white;
        padding-left: 5px;
    }
    .has-submenu.submenu-active > a::after {
        font-family: "Font Awesome 5 Free";
        font-size: 12px;
        line-height: 16px;
        font-weight: 900; 
        content: "\f077";
        color: white;
        padding-left: 5px;
    }
    .subitem a {
        padding: 10px 15px;
    }
    .submenu-active {
        background-color: #342925;
        border-radius: 3px;
    }

    /* Tablet menu */
    @media all and (min-width: 700px) {
        .menu {
            justify-content: space-between;
            margin: 0 auto 1.2rem auto;
        }
        .item.button {
            width: auto;
            order: 1;
            display: block;
        }
        .toggle {
            flex: 1;
            text-align: right;
            order: 2;
        }
        /* Button up from tablet screen */
        .menu li.button a {
            padding: 10px 15px;
            margin: 5px 0;
        }
        .button a {
            background: #0080ff;
            border: 1px royalblue solid;
        }
        .button.secondary {
            border: 0;
        }
        .button.secondary a {
            background: transparent;
            border: 1px #0080ff solid;  
        }
        .button a:hover {
            text-decoration: none;
        }
        .button:not(.secondary) a:hover {
            background: royalblue;
            border-color: darkblue;
        }
    }

    /* Desktop menu */
    @media all and (min-width: 980px) {
        .menu {
            align-items: flex-end;     
            flex-wrap: nowrap;
            background: none;
        }
        .logo {
            order: 0;
        }
        .item {
            order: 1;
            position: relative;
            display: block; 
            width: auto;
            margin-left: .75rem;
            margin-right: .75rem;
        }
        .button {
            order: 2;
        }
        .submenu-active .submenu {
            display: block;
            position: absolute;
            left: 0;
            top: 68px;
            background: #342925;
        }
        .toggle {
            display: none;
        }
        .submenu-active {
            border-radius: 0;
        }
    }
}

nav:after {
    content: '';
    display: block;
    width: calc(100% + 40px);
    height: 0.75em;
    background-color: #37c0fb;
    margin-left: -20px;
}