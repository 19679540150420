@use 'breakpoints' as *;

.alignleft {
  @media (min-width: $mobile) {
    float: left;
    margin-right: 2rem;
  }    
}
.alignright {
  @media (min-width: $mobile) {
    float: right;
    margin-left: 2rem;
  }
}
.clearboth {
    clear: both;
}
.force-wrap {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
.box {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 2rem;
}
.box-narrow {
  padding: .8rem; 
}
.max-30-pct {
  @media (min-width: $mobile) {
    max-width: 30%;
  } 
}
.inline-block {
  display: inline-block;
}