@use 'breakpoints' as *;
$backgroundColor: #ffffff;
$bodyColor: #474747;
$bodyFont: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$bodyFontSize: 1.2rem;

html,
body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  --nav-height: 113px;
  background: $backgroundColor;
  color: $bodyColor;
  display: flex;
  flex-direction: column;
  font-family: $bodyFont;
  font-size: clamp(1rem, 8vw - 2rem, 1.2rem);
  font-weight: 300;
  min-height: 100vh;
  padding-top: var(--nav-height);

  @media (max-width: $mobile) {
    --nav-height: 90px;
  }
}

h1,
h2,
h3 {
  font-family: "InterDisplay", "Source Sans Pro", sans-serif;
  font-weight: 600;
}

b,
strong {
  font-weight: 600;
}

.img-responsive {
  width: 100%;
}

.default-template {
  flex-grow: 1;

  >.container {
    padding: 2rem 0 3rem;

    @media (max-width: $mobile) {
      padding: 2rem 2rem 3rem;
    }
  }

  h1,
  h2,
  h3 {
    color: $bodyColor;
  }

  h1 {
    font-size: 2.4rem;
  }

  &.home {
    margin-top: 0;
    max-width: unset;
    width: 100%;

    a {
      color: $bodyColor;

      &:hover {
        border-bottom: 0;
        color: #858585;
      }
    }

    .blog {
      background-color: #e2f3fc;
    }

    .grid-container {
      a {
        padding: .5rem 0;

        @media (min-width: $mobile) {
          padding: 3rem 0;
        }
      }
    }
  }

  p {
    line-height: 28px;
  }

  a {
    border-bottom: 1px dotted #7ba60d;
    color: #7ba60d;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      border-bottom: 1px solid #a1cf2d;
      color: #a1cf2d;
      transition: color 0.3s ease-in-out;
    }
  }

  blockquote {
    font-family: "Source Sans Pro", Inter, sans-serif;
    font-weight: 400;
    border-left: 5px solid #a3d7ff;
    background-color: #f6f6f6;
    margin: 0;

    @media (min-width: $mobile) {
      padding: .5em 1em;
    }

    p {
      margin: 1rem .5rem;
    }
  }

  .gallery {
    a {
      border: 0;
    }
  }

  figcaption {
    color: #797979;
    font-size: 85%;
  }

  figure img {
    background-color: #fff;
    padding: 4px;
    box-shadow: 0 1px 2px #999999;
    max-width: 100%;
  }

  .note {
    color: #9e9e9e;
    font-size: 90%;
    font-style: italic;
  }
}

.container,
.grid-container {
  margin: 0 auto;
  max-width: 100%;
  width: 1400px;

  @media (max-width: $wide) {
    width: 1200px;
  }

  @media (max-width: $normal) {
    width: 960px;
  }

  @media (max-width: $narrow) {
    width: 95%;
  }

  @media (max-width: $narrower) {
    width: 95%;
  }

  @media (max-width: $mobile) {
    width: 90%;
  }

  @media (max-width: $mobilep) {
    width: 100%;
  }
}

.grid-container {
  display: grid;

  a {
    border-bottom: 0;
    color: $bodyColor;
  }

  &.three-col {
    @media (min-width: $mobile) {
      gap: 3rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &.two-col {
    @media (min-width: $mobile) {
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.center {
    text-align: center;
  }

  .grid-item {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding-top: 1rem;
    border: 0;

    &.main-section {
      transition: transform .5s ease;

      &:hover {
        transform: translateY(-5px);

        h1 {
          color: #858585;
        }
      }

      h1 {
        transition: color .5s ease;
      }
    }

    .image {
      text-align: center;

      img {
        height: auto;
        max-width: 100%;
      }
    }

    .section-text {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-left: 1rem;

      h1 {
        font-size: clamp(2rem, 8vw - 2rem, 2.4rem);
      }

      h2 {
        margin-top: 0;
      }
    }

    @media (min-width: $mobile) {
      flex-direction: column;

      .section-text {
        margin-left: 0;
        text-align: center;
      }
    }
  }
}

.blog-index {
  border-bottom: 1px solid #ffffff;
  display: flex;
  flex-direction: column;
  line-height: 2.5;
  padding: 1rem 0;

  .post-meta {
    flex-shrink: 0;
    font-size: 1rem;
    width: 20rem;
  }

  h3 {
    flex-grow: 1;
    line-height: 1.5;
    margin-bottom: 0;

    a {
      border-bottom: 0;
    }
  }

  @media screen and (min-width: $normal) {
    align-items: center;
    flex-direction: row;
    padding: .3em 0;

    .tags {
      text-align: right;
      width: 30rem;
    }
  }
}

.blog {
  padding: 1rem 0 2rem 0;


  .header-row {
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
  }

  .blog-index {
    border-bottom: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    line-height: 2.5;
    padding: 1rem 0;

    .post-meta {
      flex-shrink: 0;
      font-size: 1rem;
      width: 20rem;
    }

    h3 {
      flex-grow: 1;
      margin-bottom: 0;

      a {
        border-bottom: 0;
        color: $bodyColor;
      }
    }

    @media screen and (min-width: $normal) {
      align-items: center;
      flex-direction: row;
      padding: .3rem 0;

      .tags {
        text-align: right;
        width: 30rem;
      }
    }
  }

  h3 {
    margin-top: 0;
  }

  .grid-item {
    flex-direction: row;
    gap: 2rem;
  }
}

.blog-post {
  .post-meta {

    @media (min-width: $narrower) {
      display: flex;
    }

    font-size: 1rem;
    justify-content: space-between;
    margin-top: 1rem;

    .byline {
      margin-bottom: 1em;
    }
  }
}

.tags {

  a {
    background-color: #f5f5f5;
    border: 1px solid #e5e7eb;
    border-radius: 1rem;
    color: #474747;
    padding: .4rem .5rem;

    &:hover {
      background-color: #ffffff;
      border: 1px solid#cccccc;
      color: #474747;
      transition: background-color .5s ease;
    }
  }
}