body {
  box-sizing: border-box;
  margin: 0;
}
figure {
  display: table;
  margin: 0 0 2rem 0;
}
figcaption {
  display: table-caption;
  caption-side: bottom;
}
ul {
  line-height: 1.5;
  list-style-type: none;
  padding-left: 0;
}