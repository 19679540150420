@use 'breakpoints' as *;

/* Leaflet maps */

.map {
    width: 100%;    
    height: 100%; 
    position: relative;
}
.fullscreen-map-wrapper {
  position: relative;
  width: 100%;
  height: 900px;
}

/* Simple map includes */

.simple-map {
  display: flex; 
  flex-direction: column;
  height: 490px;
  margin: 1em 0;
  width: 100%;

  &.alignright {
    @media (min-width: $mobile) {
      margin: 1em 0 1em 1.5em;
      width: 50%;
    }
  }

  div[class*="number-icon-"] {
    border-radius: 35px;
    color: #ffffff;
    text-align: center;
  }
  .number-icon-blue {
    background-image: url("/assets/img/number-marker-icon-blue.png");
    text-align: center;
    padding-top: 8px;
    color: white;	
  }
}


/* Airway Beacons Map */

.leaflet-control-layers #counterValue  {
    display: none;
}
.leaflet-control-layers-expanded #counterValue {
    display: block;
    font-size: 125%;
    font-weight: bold;
    padding: 6px 0;  
    text-align: center; 
}

/* Recoveries Map */

#leaflet-slider {
    margin: 15px;
    display: inline-block;
}
#recoveries-map .slider {
    background: white;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.65);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    text-align: center;
    padding: 8px 5px 5px 5px;
    position: relative;
}
#recoveries-map #slider-min, #slider-max, #slider-current, #slider {
    display: inline-block;
    margin: 0 5px 0 5px;
}
#slider-timestamp {
    margin-top:13px; 
    background-color:#FFFFFF; 
    border-radius:5px;
}
#slider-timestamp-max,
#slider-timestamp-min {
    display: inline-block;
}

/* PID Prefix Map */

#pidprefix-map .info {
    padding: 10px 12px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255,255,255,0.8);
    border: 2px solid rgba(0,0,0,0.2);
    border-radius: 5px;

    h4 {
        margin: 0 0 5px;
        color: #777;
    }
}